<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            New Offices
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="homes"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <b-card class="mt-3" header="Info Home">
            <b-row>
              <b-col>
                <b-form-group id="input-group-2" label="Name:" label-for="i1">
                  <b-form-input id="i1" v-model="selected.name" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Address:" label-for="i2">
                  <b-form-input id="i2" v-model="selected.address" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="City:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.city" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="State:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.state" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Customer:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.customer" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
                <p><b>Info Home:</b></p><br>
            <b-row>
                
              <b-col>
                <b-form-group id="input-group-2" label="Total Area:" label-for="i1">
                  <b-form-input id="i1" v-model="selected.total_area" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Office:" label-for="i2">
                  <b-form-input id="i2" v-model="selected.office" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Dining Room:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.dining_room" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Living Room:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.living_room" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Kitchen:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.kitchen" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Half-Bath:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.half_bath" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Bathroom:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.bathroom" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Bedroom:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.bedroom" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
             <p><b>Info Basement:</b></p><br>
            <b-row>
                
              <b-col>
                <b-form-group id="input-group-2" label="Total Area:" label-for="i1">
                  <b-form-input id="i1" v-model="selected.total_area_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Office:" label-for="i2">
                  <b-form-input id="i2" v-model="selected.office_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Dining Room:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.dining_room_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Living Room:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.living_room_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Kitchen:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.kitchen_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Half-Bath:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.half_bath_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Bathroom:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.bathroom_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-2" label="Bedroom:" label-for="i3">
                  <b-form-input id="i3" v-model="selected.bedroom_basement" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              type="button"
              @click="selected = null"
              variant="danger"
              style="margin-right: 20px;"
            >RETURN</b-button>
            <b-button
              type="button"
              @click="approve(selected.id)"
              variant="primary"
              style="margin-right: 20px;"
            >APPROVE</b-button>
            <b-button
              type="button"
              @click="recuse(selected.id)"
              variant="danger"
              style="margin-right: 20px;"
            >RECUSE</b-button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "new-offices",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

     approve(id){
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=approveNewOffice&id='+id)
      .then(window.location.reload()).catch(console.log)
  } ,

  recuse(id){
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=refuseNewOffice&id='+id)
      .then(window.location.reload()).catch(console.log)
  }
  },
  data() {
    return {
      homes: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "City", value: "city" }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    var auth = localStorage.getItem("name");
    if(auth != null && auth != 'undefined'){
        console.log('existe')
    }else{
        console.log('nao existe')
        this.$router.push({ name: "login" });
    }

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readNewOffices")
      .then(res => res.json())
      .then(data => {
        this.homes = data.home;
        //console.log('RES: '+ JSON.stringify(data.independent));
      })
      .catch(console.log);
  }
};
</script>
